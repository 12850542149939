<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        image="home.png"
        title="We make mortgages easy."
        leading="There are so many mortgages on the market, it takes an expert to know where to turn. So
        if you’re wondering how to ﬁnd the right mortgage deal, just let our expert team take the strain."
        :points="[
            'We ﬁnd you the best mortgage deal from our panel of top lenders',
            'Our bespoke technology makes everything easy',
            'Our friendly, expert team supports you all the way',
            'Access to exclusive offers',
            'Great mortgage deals without the hassle'
            ]"
    />
    <TrustPilot />
    <InfoSection
        image="home-alt"
        title="Mortgages made simple."
        leading="We make it quick and easy to ﬁnd the right mortgage, and our application process is straightforward and simple."
        :points="[
            'Remortgages from £50,000 to £10,000,000',
            'No up front fees',
            'Access to exclusive offers',
            'As a leading Credit Broker and not a lender we search a panel of lenders',
            '5 Star customer service awarded by our customers'
            ]"
        :counter="true"
        :swap="true"
    />

    <InfoSection
        image="searching-alt"
        title="We work hard - you get the best deal."
    >
      <p class="font-weight-bold mt-3">
        Because we’re a credit broker, not a lender, we’re able to search a panel of leading
        lenders to find the mortgage deal that suits you best.
      </p>
      <p>Got a less-than-perfect credit history? No need to worry.</p>
      <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
      <p>Even if you’ve been made bankrupt,  Believe could still help get your ﬁnances back on track.</p>
    </InfoSection>
    <HowItWorks/>
    <Contact />
    <!--<Lenders />-->
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
//import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    //Lenders,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
