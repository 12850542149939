<template>
  <div class="how-it-works pt-4 pb-4">
    <div class="container-fluid">
      <div class="container text-center">
        <h2 class="title">How it works</h2>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <img src="@/assets/how-it-works/step-1.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 1.</h3>
            <p>Simple, easy application</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <img src="@/assets/how-it-works/step-2-alt.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 2.</h3>
            <p>We search our panel of lenders to find the right deal for you</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <img src="@/assets/how-it-works/step-3-alt.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 3.</h3>
            <p>Our bespoke technology and skilled advisors support you all the way</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <img src="@/assets/how-it-works/step-4.svg" class="img-fluid col-8 col-sm-12 pb-4"/>
            <h3>Step 4.</h3>
            <p>The money lands in your bank account – usually within two weeks</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 order-sm-1 text-sm-left">
            <button class="btn btn-xl mt-3 mb-3 more"
                    type="button"
                    name="button"
                    @click="() => { poweredBy = !poweredBy }"
            >
              Find out more {{ poweredBy ? "&#9650;" : "&#9660;"}}
            </button>
          </div>
          <div class="col-12 col-sm-6 order-sm-0 text-sm-right">
            <button class="btn btn-xl btn-secondary mt-3 mb-3"
                    type="button"
                    name="button"
                    @click="scrollTop"
            >Let's get started</button>
        </div>
        </div>
        <div :class="`row pt-5 click-tech  ${poweredBy ? `` : `d-none`}`">
          <div class="col-12 col-md-6 order-md-1 text-left">
            <h3 class="mb-4">Believe is powered by Clicktech</h3>
            <p>We want you to get your quote quickly and start your loan journey as soon as
              you’re ready. So we’ve developed a powerful technology platform, called Clicktech,
              that makes the process fast and efficient with you in control.</p>
            <p>We offer lots of ways for you to connect with us and get your quotes, with digital
              journeys designed to keep you in control. We search through hundreds of products and
              deliver the most competitive quotes in moments – it’s so quick and easy to make your choice.</p>
            <p>Following a chat with one of our friendly advisors, we link you to our online portal where
              you can enter the brief information we need. From uploading paperwork and signing documents
              to confirming your identity, it all happens online. It’s smooth, simple and stress-free!</p>
            <p>And once you’ve made your choice, Clicktech keeps things moving, helping you get your money
              in as few as ten days.</p>
          </div>
          <div class="col-12 col-md-6 order-md-0">
            <img src="@/assets/how-it-works/clicktech.svg" class="img-fluid p-5" alt="Click Tech"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "HowItWorks",
      data() {
        return {
          poweredBy: false
        }
      },
      methods: {
        scrollTop() {
          window.scrollTo(0, 0);
        }
      }
    }
</script>

<style lang="scss" scoped>

.how-it-works {
  background-color: rgba(107,179,198,0.2);
  color: #17174C;
  h3 {
    font-size:1.25rem;
  }
  p {
    font-size:1rem;
  }
  button {
    font-size: 1.2rem;
    border-radius: 2rem;
    padding: 1rem 2rem;
  }
  button.more {
    color:white;
    background-color: #17174C;
  }
  .click-tech {
    animation: ease-in-out;
    transition: display;
  }
}

</style>
