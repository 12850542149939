<template>
  <div/>
</template>
<script>
    const loader = new Promise((resolve, reject) => {
        if (window.ClickAppForm) {
            resolve(window.ClickAppForm);
            return;
        }
        const script = document.createElement("script");
        script.src = process.env.VUE_APP_CLICK_APPLY_WIDGET;
        script.crossOrigin = "anonymous";
        script.onload = () => {
            resolve(window.ClickAppForm);
        };
        script.onerror = reject;
        document.body.appendChild(script);
    });

    export default {
        props: {
            mode: String
        },
        async mounted() {
            console.log(this.mode);
            const tracking = await window.ClickAppTrack;
            tracking.applicationURL = location.href;
            await loader.then(ClickAppForm => {
                ClickAppForm.default(this.$el, {
                    leadSource: "Believe Mortgages",
                    phoneNumber: "01302 591 371",
                    documentURLs: {
                        privacyNotice: "/privacy",
                        terms: "/terms-and-conditions"
                    },
                    tracking: tracking,
                    initialValues: {...this.$route.query, "residentialStatus": "homeowner", "mortgageType": this.mode.toLowerCase().replace(/\s+/g, '')},
                    minLoanAmount: 50000,
                    maxLoanAmount: 10000000,
                    mode: this.mode,
                    believe: true,
                    redirectOnComplete: this.mode === "Remortgage" || this.mode === "Buy To Let" ? "/thank-you" : "/thank-you-2"
                });
            }).then(() => window.addEventListener("message", event => {
               if (event.data.type === "scroll") {
                  window.scrollTo(0, 0);
                }
            }));
        }
    };
</script>
