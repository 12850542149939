<template>
  <div id="form-1" class="apply-button d-flex w-100 h-100 text-center justify-content-center p-0 m-0">
    <button class="btn btn-xl btn-secondary align-middle d-flex flex-column align-items-center justify-content-center ctaButton m-3 p-3"
            type="button"
            name="button"
            @click="onButtonClicked('Hybrid')"
    >Let's get started</button>
  </div>
</template>

<script>
export default {
  name: "ApplyButton",
  methods: {
    onButtonClicked(form) {
      this.$emit("selectForm", form);
    }
  }
}
</script>

<style scoped>

#form-1 {
  padding:0 !important;;
}

.apply-button {
  flex-direction: column;
  margin-top:10px;
  justify-content: space-between;
}

.apply-button h5 {
  font-size: 1.4rem;
  color:#dd1d42 !important;
}

.apply-button .ctaButton {
  font-size:1rem;
  font-weight: bold;
  border-radius: 1rem;
}

@media (max-width: 991px){
  .apply-button h5 {
    font-size: 1rem;
  }
  .apply-button .ctaButton {
    font-size: 1rem;
  }
}

@media (max-width: 767px){
  .apply-button h5 {
    font-size: 1.4rem;
  }
  .apply-button p {
    color:#17174C;
    font-size: 12px;
  }
  .apply-button .ctaButton {
    font-size:1.4rem;
  }
}

@media (max-width: 420px){
  .apply-button h5 {
    font-size: 1em;
  }
  .apply-button .ctaButton {
    font-size: 1rem;
  }
}

</style>
