var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"appContainer"},[_c('Header',{style:({ 'padding-top': (_vm.headerHeight + "px") })}),_c('Hero',{attrs:{"image":"home.png","title":"We make mortgages easy.","leading":"There are so many mortgages on the market, it takes an expert to know where to turn. So\n      if you’re wondering how to ﬁnd the right mortgage deal, just let our expert team take the strain.","points":[
          'We ﬁnd you the best mortgage deal from our panel of top lenders',
          'Our bespoke technology makes everything easy',
          'Our friendly, expert team supports you all the way',
          'Access to exclusive offers',
          'Great mortgage deals without the hassle'
          ]}}),_c('TrustPilot'),_c('InfoSection',{attrs:{"image":"home-alt","title":"Mortgages made simple.","leading":"We make it quick and easy to ﬁnd the right mortgage, and our application process is straightforward and simple.","points":[
          'Remortgages from £50,000 to £10,000,000',
          'No up front fees',
          'Access to exclusive offers',
          'As a leading Credit Broker and not a lender we search a panel of lenders',
          '5 Star customer service awarded by our customers'
          ],"counter":true,"swap":true}}),_c('InfoSection',{attrs:{"image":"searching-alt","title":"We work hard - you get the best deal."}},[_c('p',{staticClass:"font-weight-bold mt-3"},[_vm._v(" Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to find the mortgage deal that suits you best. ")]),_c('p',[_vm._v("Got a less-than-perfect credit history? No need to worry.")]),_c('p',[_vm._v("Got CCJs or defaults? The chances are, we’ll still be able to help.")]),_c('p',[_vm._v("Even if you’ve been made bankrupt, Believe could still help get your ﬁnances back on track.")])]),_c('HowItWorks'),_c('Contact'),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }