<template>
  <div ref="appContainer">
    <Header :style="{ 'padding-top': `${headerHeight}px` }"></Header>
    <Hero
        title="We make mortgages easy."
        leading="With so many mortgages available, it takes an expert to ﬁnd the right deal. We are here to help."
        :points="[
            'Remortgages from £50,000 to £10,000,000',
            'Access To exclusive offers',
            'Save hundreds or raise extra funds'
            ]"
    />
    <TrustPilot />
    <InfoSection
        image="home-alt"
        title="Mortgages made simple."
        :counter="true"
        :swap="true"
    >
      <p class="mt-3">Because we’re a credit broker, not a lender, we’re able to search a panel of leading lenders to ﬁnd the mortgage deal that suits you best.</p>
      <p>Got a less-than-perfect credit history? No need to worry.</p>
      <p>Got CCJs or defaults? The chances are, we’ll still be able to help.</p>
      <p>Even if you’ve been made bankrupt, Believe could still help get your ﬁnances back on track.</p>
    </InfoSection>

    <InfoSection
        image="searching-alt"
        title="We work hard - you get the best deal."
    >
      <p class="mt-3">Enjoy a smooth, stress-free journey as we guide you through a world of lenders, rates and options to ﬁnd the right mortgage deal.</p>
      <p>We combine powerful technology and personal expertise to search out the best deals with advice tailored to you and your circumstances.</p>
      <p>We keep paperwork to a minimum, using secure online validation, ID and document checks to cut out hassles and headaches.</p>
      <p>Our expert team keeps things moving with all the personal support you need.</p>
    </InfoSection>
    <HowItWorks/>
    <Contact />
    <!--<Lenders />-->
    <Footer />
  </div>
</template>

<script>
import { Bus } from '../bus'

import Header from '../Sections/Header.vue'
import Hero from '../Sections/Hero.vue'
import TrustPilot from '../Sections/TrustPilot.vue'
import InfoSection from "../Sections/InfoSection";
import Contact from '../Sections/Contact.vue'
//import Lenders from '../Sections/Lenders.vue'
import Footer from '../Sections/Footer.vue'
import HowItWorks from "../Sections/HowItWorks";

export default {
  name: 'app',
  components: {
    HowItWorks,
    InfoSection,
    Header,
    Hero,
    TrustPilot,
    Contact,
    //Lenders,
    Footer
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    headerHeight() {
      return this.height;
    }
  },
  created() {
    Bus.$on("headerHeight", (height) => {
      this.height = height;
    });
  },
  mounted() {
    window.addEventListener("message", event => {
      if (event.data.type === "scroll") {
        const iframePosition = document.getElementsByTagName('iframe')[0].getBoundingClientRect();
        event.data.offset -= this.height;
        if (event.data.offset < 0) {
          window.scrollTo(iframePosition.left, this.height);
          return;
        }
        const newScrollY =
            iframePosition.top + event.data.offset + window.scrollY;
        if (newScrollY < window.scrollY) {
          window.scrollTo(iframePosition.left, newScrollY);
        }
      }
    });
  }
}
</script>
