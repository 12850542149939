import Main from './Views/Home'
import DebtConsolidation from "./Views/DebtConsolidation";
import BuyToLet from "./Views/BuyToLet";
import PrivacyPolicy from './Views/Policy/PrivacyPolicy'
import ThankYou from "./Views/ThankYou";
import TermsAndConditions from "@/Views/Policy/TermsAndConditions";
import CookiePolicy from "./Views/Policy/CookiePolicy";
import ComplaintPolicy from './Views/Policy/ComplaintsPolicy';

const root = "";

export const routes = [
    {
        name: "Home",
        path: "/",
        component: Main
    },
    {
        name: "BuyToLet",
        path:`${root}/buy-to-let`,
        component: BuyToLet
    },
    {
        name: "DebtConsolidation",
        path:`${root}/debt-consolidation`,
        component: DebtConsolidation
    },
    {
        path:`${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Thank You",
        path: `${root}/thank-you`,
        component: ThankYou
    },
    {
        name: "Cookies",
        path: `${root}/cookies`,
        component: CookiePolicy
    },
    {
        name: "Privacy",
        path: `${root}/privacy`,
        component: PrivacyPolicy
    },
    {
        name: "Terms",
        path: `${root}/terms`,
        component: TermsAndConditions
    },
    {
        name: "Complaints",
        path: `${root}/complaints`,
        component: ComplaintPolicy
    },

];
