<template>
  <div>
    <nav ref="header" class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
      <div class="container">
        <div class="col-4 col-sm-5">
          <router-link class="navbar-brand" to="/">
            <img @click="headerLogoClicked" src="@/assets/logo-believe-finance.svg" class="logo" :alt="branding.shortName">
          </router-link>
        </div>

        <div class="col-8 col-sm-7 d-flex align-items-center justify-content-end">
          <a :href="`https://uk.trustpilot.com/review/${branding.website.shortUrl}`" target="_blank"
             class="mr-5 d-none d-md-block">
            <img style="float:right" src="@/assets/header-trustpilot.svg" width="110" alt="">
          </a>
          <div class="call">
            <h3 class="navText">Call <span class="d-none d-sm-inline">from a mobile or Landline:</span></h3>
            <p class="navNumber"><a class="rulertel" :href="`tel:${branding.phone.replaceAll(' ', '')}`">{{ branding.phone }}</a></p>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Bus } from "../bus";
import {branding} from "@/branding";

export default {
  mounted() {
    Bus.$emit('headerHeight', this.$refs.header.clientHeight);
  },
  data() {
    return {
      branding: branding
    }
  },
  methods: {
    headerLogoClicked() {
      Bus.$emit('headerLogoClicked');
    }
  }
}
</script>

<style scoped>

.logo {
  width: 200px;
}

.navText,.navNumber {
  font-family: Montserrat;
  text-align: right;
  line-height: 24px;
}

.navText {
  color: #0e2428;
  font-size: 0.875em;
  margin: 0.75em 0 0.25em;
}

.navNumber {
  font-weight: 600;
  color: #F15A24 !important;
  font-size: 1.1em;
}

.navNumber a {
  color: inherit;
}

@media (max-width: 768px){
  .navText {
    font-size: 0.8em;
    width: 100%;
  }
  .logo {
    width: 150px;
  }
}

@media (max-width: 430px){
  .navText {
    margin-top:10px;
    font-size: 0.6em;
  }
}

@media (max-width: 390px){
  .logo {
    width:120px;
  }
}

@media (max-width: 350px){
  .navText {
    display:none;
  }
  .navNumber {
    margin: 26px 0px;
  }
}

</style>
